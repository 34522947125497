body {
    font-family: $text-fontfamily;
    font-size: 1rem;
    font-weight: 300;
    hyphens: auto;
}

b, strong {
  font-weight: bold;
}

@import "headings";
