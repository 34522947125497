.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-family: $headline-fontfamily;
    font-weight: 600;
}

h1,
h2 {
    margin-top: 2rem;
}

h1 {
    color: $headline-color;
    font-size: 4rem;
    margin-top: 0;
    text-align: center;
}

h2 {
    color: $headline-color;
    font-size: 1.2rem;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;

    a {
        color: $headline-color;

        &:hover,
        &:focus {
            color: $headline-color;
        }
    }
}

h3 {
    font-size: 1.7rem;
}

h4 {
    margin-top: 1.5rem;
    font-size: 1.3rem;
}

@include media-breakpoint-up(sm) {
    h2 {
        font-size: 1.7rem;
    }
}

@include media-breakpoint-up(md) {
    h1 {
        font-size: 5rem;
    }
    
    h2 {
        font-size: 2rem;
    }
    
    h3 {
        font-size: 2rem;
    }
    
    h4 {
        font-size: 1.5rem;
    }
}
