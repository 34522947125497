// corporate colors
$th-red:                                #CF1820;
$th-orange:                             #EC6525;
$th-purple:                             #AF368C;

$primary:                               $th-red;
$secondary:                             $th-orange;
$tertiary:                              $th-purple;
$white:                                 #FFFFFF;
$black:                                 #000000;
$darkgray:                              #1F1E1E;
$gray:                                  #5f5f5f;
$gray-light:                            #f7f7f9;
$gray-semilight:                        #efefef;

$body-color:                            $darkgray;
$body-bg:                               $white;

$headline-color:                        $primary;

$link-color:                            $primary;
$link-decoration:                       none;
$link-hover-color:                      $tertiary;
$link-hover-decoration:                 underline;
